<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" justify="space-between" :gutter="20">
				<!-- <el-col>
					<span class="text-primary text-pad-right">设备ID</span>
					<el-input v-model="equipmentId" type="text" class="input searchInput" suffix-icon="el-icon-search" placeholder="请输入设备ID"
					 @input="changeCompany" />
				</el-col> -->
				<el-col>
					<span class="text-primary text-pad-right">设备ID</span>
					<!-- <el-select v-model="equipmentId" class="input searchInput" filterable clearable @change="changeCompany('1')">
						<el-option v-for="(v, i) in deviceList" :key="i" :value="v" :label="v" />
					</el-select> -->
					<el-input v-model="equipmentId" size="mini" type="text" class="input searchInput" suffix-icon="el-icon-search" placeholder="请输入设备ID"
					 @input="changeCompany('1')" />
				</el-col>
				<el-col>
					<span class="text-primary text-pad-right">设备手机号</span>
					<el-input v-model="equipmentNum" size="mini" type="text" class="input searchInput" suffix-icon="el-icon-search" placeholder="请输入设备手机号"
					 @input="changeCompany('1')" />
				</el-col>
				<el-col v-if="$admin">
					<span class="text-primary text-pad-right">公司</span>
					<el-select size="mini" v-model="companyId" class="input searchInput" filterable clearable @change="changeCompany('2')">
						<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
					</el-select>
				</el-col>
				<el-col>
					<span class="text-primary text-pad-right">设备类型</span>
					<el-select size="mini" v-model="deviceType" class="input searchInput" filterable clearable @change="changeCompany('1')">
						<el-option v-for="(v, i) in parentCodeList" :key="i" :value="v.dictCode" :label="v.dictName" />
					</el-select>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button type="primary" size="mini" @click="openDialog({title:'添加设备',addOr:'add'})">添加设备</el-button>
								<el-button type="danger" size="mini" @click="removeAll">批量删除</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="shuaxin">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="630" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading"
				 @checkbox-all="handleSelectionChange" @checkbox-change="handleSelectionChange">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="deviceTypeDesc" title="设备类型" show-overflow="title" />
					<vxe-table-column field="deviceId" title="设备ID" show-overflow="title" />
					<vxe-table-column field="clientId" title="设备手机号" show-overflow="title" />
					<vxe-table-column field="companyName" title="所属公司" show-overflow="title" />
					<vxe-table-column field="remarks" title="备注" show-overflow="title" />
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改设备',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item icon="el-icon-delete" @click.native="remove(row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import Operate from './Operate'
	import {
		company,
	} from "@/api";
	export default {
		name: 'Index',
		components: {
			Operate
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false
				},
				companyId: this.$admin ? null : this.$companyId,
				company: [],
				equipmentId: '',
				equipmentNum: '',
				equipmentIds: [],
				deviceList: '',
				parentCodeList: [],
				deviceType: ''
			}
		},
		created() {
			this.getCompany();
			this.getDevice();
			this.getParentCode();
			if (!this.$admin) {
				this.getList();
			}
		},
		methods: {
			rowStyle,
			headerStyle,
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			refreshDialog() {
				this.dialog.refresh = false
				this.shuaxin();
			},
			getParentCode() {
				this.$axiosReq(
					'/data/server/escort/web/dict/parentCode/DEVICE_TYPE',
					null, null,
					"get"
				).then((res) => {
					this.parentCodeList = res.data;
				});
			},
			getDevice() {
				this.$axiosReq(
					'/car/server/escort/web/jt808/device',
					null, null,
					"get"
				).then((res) => {
					this.deviceList = res.data;
				});
			},
			getCompany() {
				this.$axiosReq(
					company,
					null, {
						pageSize: 10000,
					},
					"get"
				).then((res) => {
					this.company = res.data.content;
					if (this.$admin) {
						this.companyId = this.company[0].id;
						this.getList();
					}
				});
			},
			changeCompany(num) {
				this.table.currentPage = 1;
				this.equipmentIds = [];
				this.getList();
			},
			shuaxin() {
				this.table.currentPage = 1;
				this.equipmentNum = '';
				this.equipmentId = '';
				this.equipmentIds = [];
				this.getList();
			},
			getList() {
				this.loading = true;
				this.$axiosReq(
						'/car/server/escort/web/jt808',
						null, {
							clientId: this.equipmentNum,
							companyId: this.companyId,
							deviceId: this.equipmentId,
							deviceType: this.deviceType,
							currentPage: this.table.currentPage,
							pageSize: this.table.pageSize,
						},
						"get"
					)
					.then((res) => {
						this.table.list = res.data.content;
						this.table.total = Number(res.data.totalElements);
					})
					.finally((res) => {
						this.loading = false;
					});
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.equipmentIds = [];
				this.table.list = [];
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.table.list = [];
				this.equipmentIds = [];
				this.getList();
			},
			removeAll() {
				var that_ = this;
				if (that_.equipmentIds.length < 1) {
					this.$message.error("请勾选需要删除的选项");
					return false;
				}
				this.$confirm("确认批量删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/car/server/escort/web/jt808',
							that_.equipmentIds,
							null,
							"delete"
						).then((res) => {
							//console.log(JSON.stringify(res))
							that_.$message.success("批量删除成功");
							that_.equipmentIds = [];
							that_.getList();
						});
					})
					.catch(() => {
						that_.$message.info("取消了批量删除！！！");
					});
			},
			remove(row) {
				this.$confirm("确认删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/car/server/escort/web/jt808/' + row.id,
							null,
							null,
							"delete"
						).then((res) => {
							this.$message.success(res.msg);
							this.equipmentIds = [];
							this.getList();
						});
					})
					.catch(() => {
						this.$message.info("取消了删除！！！");
					});
			},
			handleSelectionChange(val) {
				const data = val.records;
				this.equipmentIds = [];
				for (let i = 0; i < data.length; i++) {
					this.equipmentIds.push(data[i].id);
				}
			},
		}
	}
</script>

<style scoped>
	.rowBox {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.searchInput {
		width: 190px;
	}
</style>
