<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<div style="width: 100%;height: 20px;"></div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form ref="ruleForm" :model="form" label-width="110px" class="allForm">
					<el-form-item label="设备ID" :required="true">
						<el-input v-model="form.deviceId" class="formInput" maxlength="25" />
					</el-form-item>
					<el-form-item label="设备类型" :required="true">
						<el-select v-model="form.deviceType" class="formInput w-100" @change="(val)=>{
                         form.deviceType==='2'&&  getUserList()
						}">
							<el-option v-for="(item,index) in deviceTypeList" :key="index" :label="item.dictName" :value="item.dictCode"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备手机号" :required="true">
						<el-input v-model="form.clientId" class="formInput" maxlength="25" />
					</el-form-item>
					<el-form-item label="所属企业" :required="true">
						<el-select v-model="form.companyId" filterable class="formInput w-100" @change="()=>{
                          form.deviceType==='2' &&  getUserList()
						}">
							<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
						</el-select>
					</el-form-item>
					<el-form-item v-if="form.deviceType == 2" label="绑定人员">
						<el-select v-model="form.userId" filterable class="formInput w-100">
							<el-option v-for="(item,index) in userList" :key="index" :label="item.realName" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="form.remarks" class="formInput" maxlength="25" />
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		company,
	} from "@/api";
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: true,
				form: {
					clientId: '',
					deviceId: '',
					companyId: this.$admin ? null : this.$companyId,
					remarks: '',
					deviceType: '',
					userId: ''
				},
				company: [],
				userList: [],
				deviceTypeList: [],
			}
		},
		created() {
			this.getCompany();
			this.getParentCode();
		},
		mounted() {
			if (this.info.addOr == 'upd') {
				this.getUpd();
			}
		},
		methods: {
			getParentCode() {
				this.loading = true;
				this.$axiosReq('/data/server/escort/web/dict/parentCode/DEVICE_TYPE', null, null, "get")
					.then((res) => {
						if (res.data && res.data.length > 0) {
							this.deviceTypeList = res.data;
						}
					});
			},
			close() {
				this.$emit('refresh')
				this.show = false
			},
			getCompany() {
				this.$axiosReq(
					company,
					null, {
						pageSize: 10000,
					},
					"get"
				).then((res) => {
					this.company = res.data.content;
				});
			},
			getUserList() {
				if (this.form.companyId) {
					this.$axiosReq(
						'/oauth/server/escort/web/user',
						null, {
							pageSize: 10000,
							companyId: this.form.companyId
						},
						"get"
					).then((res) => {
						this.userList = res.data.content;
					});
				}
			},
			commit() {
				if (!this.form.deviceId) {
					this.$message.error("请输入设备ID");
					return false;
				}
				if (!this.form.clientId) {
					this.$message.error("请输入设备手机号");
					return false;
				}
				if (!this.form.companyId) {
					this.$message.error("请选择公司");
					return false;
				}
				if (this.info.addOr == 'upd') {
					this.$axiosReq(
						'/car/server/escort/web/jt808/' + this.row.id,
						this.form, null,
						"put"
					).then((res) => {
						this.close();
					});
				} else{
					this.$axiosReq(
						'/car/server/escort/web/jt808',
						this.form, null,
						"post"
					).then((res) => {
						this.close();
					});
				}
			},
			getUpd() {
				this.$axiosReq(
					'/car/server/escort/web/jt808/' + this.row.id,
					null, null,
					"get"
				).then((res) => {
					this.form = res.data;
					this.getUserList();
				});
			},
		}
	}
</script>

<style scoped>
	.input-with-select {
		width: 280px;
	}

	.pb-20 {
		padding-bottom: 20px;
	}
</style>
